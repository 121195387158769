import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../UI/Button";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { dateFormat } from "../../util/DateUtil";

const EventoTableSelectView = ({
  listEventos,
  handleEventoChangeRowsPerPage,
  sizeEvento,
  handleEventoChangePage,
  setEvento,
  isLoadingEvento,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Title>Selecione um Evento</Title>
      </Grid>
      <TablePagination
        component="div"
        count={listEventos.totalElements}
        page={listEventos.number ? listEventos.number : 0}
        onPageChange={handleEventoChangePage}
        rowsPerPage={sizeEvento}
        onRowsPerPageChange={handleEventoChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Data Início</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Cidade</TableCell>
            <TableCell>Local</TableCell>
            <TableCell>Agência Evento</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingEvento ? (
            <Loading description="Carregando Eventos..." />
          ) : (
            <>
              {listEventos.content.length === 0 ? (
                <Typography sx={{ py: 3 }}>Nenhum evento cadastrado</Typography>
              ) : (
                <>
                  {listEventos.content.map((evento) => (
                    <TableRow>
                      <TableCell>
                        {evento.descricao ? evento.descricao : ""}
                      </TableCell>
                      <TableCell>
                        {evento.dataEvento ? dateFormat(evento.dataEvento) : ""}
                      </TableCell>
                      <TableCell>{evento.pais ? evento.pais : ""}</TableCell>
                      <TableCell>
                        {evento.estado ? evento.estado : ""}
                      </TableCell>
                      <TableCell>
                        {evento.cidade ? evento.cidade : ""}
                      </TableCell>
                      <TableCell>{evento.local ? evento.local : ""}</TableCell>
                      <TableCell>{evento.agenciaEvento ? evento.agenciaEvento.descricao : ""}</TableCell>

                      <TableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => setEvento(evento)}
                        >
                          Selecionar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={listEventos.totalElements ? listEventos.totalElements : 0}
        page={listEventos.number ? listEventos.number : 0}
        onPageChange={handleEventoChangePage}
        rowsPerPage={sizeEvento}
        onRowsPerPageChange={handleEventoChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default EventoTableSelectView;
