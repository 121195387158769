import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { errorMessage } from "../util/ConfirmAlertUtil";
import { page } from "../Evento/api-evento";
import { confirmAlert } from "react-confirm-alert";
import {
  findByEventoCodigoAndCoreografiaCodigo,
  removeCoreografiaPagamento,
  saveCoreografiaPagamento,
} from "../CoreografiaPagamento/api-coreografia-pagamento";
import CoreografiaPagamentoForm from "../CoreografiaPagamento/CoreografiaPagamentoForm";
import CoreografiaPagamentoTable from "../CoreografiaPagamento/CoreografiaPagamentoTable";
import EventoTableSelectView from "../Evento/EventoTableSelectView";
import { findCoreografiaByEventoCodigo } from "../Coreografia/api-coreografia";
import CoreografiaTableSelectView from "../Coreografia/CoreografiaTableSelectView";
import EventoProfile from "../Evento/EventoProfile";
import CoreografiaProfile from "../Coreografia/CoreografiaProfile";
import Button from "../UI/Button";

const EventoCoreografiaPagamentoContainer = () => {
  const [listEventos, setListEventos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [evento, setEvento] = useState(null);
  const [listCoreografias, setListCoreografias] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografia, setCoreografia] = useState(null);
  const [listCoreografiaPagamentos, setListCoreografiaPagamentos] = useState({
    content: [],
    size: 0,
    number: 0,
    totalElements: 0,
  });
  const [coreografiaPagamento, setCoreografiaPagamento] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);

  useEffect(() => {
    fetchDataEvento(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evento) {
      fetchDataCoreografia(0);
    }
    // eslint-disable-next-line
  }, [evento]);

  useEffect(() => {
    if (coreografia) {
      fetchDataCoreografiaPagamento(0);
    }
    // eslint-disable-next-line
  }, [coreografia]);

  const handleChangePage = (event, newPage) => {
    fetchDataEvento(newPage);
  };

  const handleCoreografiaChangePage = (event, newPage) => {
    fetchDataCoreografia(newPage);
  };

  const fetchDataCoreografiaPagamento = (newPage) => {
    setIsLoading(true);
    findByEventoCodigoAndCoreografiaCodigo(evento.codigo, coreografia.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografiaPagamentos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchDataCoreografia = (newPage) => {
    setIsLoading(true);
    findCoreografiaByEventoCodigo(evento.codigo, newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListCoreografias(data);
        }
      }
      setIsLoading(false);
    });
  };

  const fetchDataEvento = (newPage) => {
    setIsLoading(true);
    page(newPage, size).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          setListEventos(data);
        }
      }
      setIsLoading(false);
    });
  };

  const handleEventoChangePage = (event, newPage) => {
    fetchDataEvento(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const handleDelete = (codigo) => {
    confirmAlert({
      title: "Alerta",
      message: "Deseja deletar este pagamento?",
      buttons: [
        {
          label: "Sim",
          onClick: () => {
            setIsLoadingRemove(true);
            removeCoreografiaPagamento(codigo).then((data) => {
              if (data.error) {
                errorMessage(data.message);
              } else {
                setListCoreografiaPagamentos((coreografiaPagamentoElement) => [
                  coreografiaPagamentoElement.content.filter(
                    (element) => element.codigo !== codigo
                  ),
                ]);
                setEvento(null);
                toast.success("Registro excluído com Sucesso.");
              }
              setIsLoadingRemove(false);
            });
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleSave = () => {
    if (evento.descricao === null || evento.descricao === "") {
      errorMessage("Campo Descrição não pode ser vazio.");
    }
    const eNovo = evento.eNovo;
    saveCoreografiaPagamento(coreografiaPagamento).then((data) => {
      if (data) {
        if (data.error) {
          errorMessage(data.message);
        } else {
          if (eNovo) {
            setListCoreografiaPagamentos((prevState) => ({
              ...prevState,
              content: [data, ...prevState.content],
            }));
          } else {
            setListCoreografiaPagamentos((prevState) => ({
              ...prevState,
              content: [
                prevState.content.map((content) => {
                  if (content.codigo === data.codigo) {
                    return data;
                  } else {
                    return content;
                  }
                }),
              ]
            }));
          }
          setEvento(data);
          toast.success("Operação Realizada com Sucesso.");
        }
      }
    });
  };

  const handleTrocaEvento = () => {
    setEvento(null);
  }

  const handleTrocaCoreografia = () => {
    setCoreografia(null);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          {evento ? (
            <>
              {coreografia ?
                <>
                  {coreografiaPagamento ? (
                    <CoreografiaPagamentoForm
                      coreografiaPagamento={coreografiaPagamento}
                      setCoreografiaPagamento={setCoreografiaPagamento}
                      handleSave={handleSave}
                      handleDelete={handleDelete}
                      isLoadingRemove={isLoadingRemove}
                    />
                  ) : (
                    <>
                      <CoreografiaProfile
                        coreografia={coreografia}
                        handleTrocaCoreografia={handleTrocaCoreografia}
                      />
                      <Grid item xs={3} sx={{ my: 2 }}>
                        <Button
                          onClick={() => setCoreografiaPagamento({
                            eNovo: true,
                            coreografia: coreografia
                          })}>
                          Novo Registro
                        </Button>
                      </Grid>
                      <CoreografiaPagamentoTable
                        listCoreografiaPagamentos={listCoreografiaPagamentos}
                        handleChangePage={handleChangePage}
                        isLoading={isLoading}
                        size={size}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        setCoreografiaPagamento={setCoreografiaPagamento}
                      />
                    </>
                  )}
                </> :
                <>
                  <EventoProfile
                    evento={evento}
                    handleTrocaEvento={handleTrocaEvento}
                  />
                  <CoreografiaTableSelectView
                    listCoreografias={listCoreografias}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    size={size}
                    handleCoreografiaChangePage={handleCoreografiaChangePage}
                    setCoreografia={setCoreografia}
                    isLoading={isLoading}
                  />
                </>
              }
            </>
          ) : (
            <>
              <EventoTableSelectView
                listEventos={listEventos}
                handleEventoChangeRowsPerPage={handleChangeRowsPerPage}
                sizeEvento={size}
                handleEventoChangePage={handleEventoChangePage}
                setEvento={setEvento}
                isLoadingEvento={isLoading}
              />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventoCoreografiaPagamentoContainer;
