import moment from 'moment';


const dateFormat = (dateString) => {
    const result = moment.utc(dateString).format("DD/MM/yyyy");
    return result;
}

const dateFormatMoment = (dateString) => {
    const result = moment(dateString);
    return result.toISOString();
}

const dateTimeFormat = (dateString) => {
    return moment(new Date(dateString), "America/Sao_Paulo").format("DD/MM/yyyy HH:mm");
}

export const timeFormatTz = (dateString) => {
    return moment(new Date(dateString), "America/Sao_Paulo").format("HH:mm");
}

const dateTimeTz = (dateString) => {
    return moment(dateString, "America/Sao_Paulo");
}

const timeFormat = (dateString) => {
    const result = moment(dateString).format("HH:mm");
    return result;
}

export const isDate = (dateString) => {
    return moment(dateString, moment.ISO_8601, true).isValid();
}

const dayOfWeekList = [
    { description: 'Domingo', value: 0 },
    { description: 'Segunda-feita', value: 1 },
    { description: 'Terça-Feira', value: 2 },
    { description: 'Quarta-Feira', value: 3 },
    { description: 'Quinta-Feira', value: 4 },
    { description: 'Sexta-Feira', value: 5 },
    { description: 'Sábado', value: 6 }

]

export const monthList = [
    { description: 'Janeiro', value: 1 },
    { description: 'Fevereiro', value: 2 },
    { description: 'Março', value: 3 },
    { description: 'Abril', value: 4 },
    { description: 'Maio', value: 5 },
    { description: 'Junho', value: 6 },
    { description: 'Julho', value: 7 },
    { description: 'Agosto', value: 8 },
    { description: 'Setembro', value: 9 },
    { description: 'Outubro', value: 10 },
    { description: 'Novembro', value: 11 },
    { description: 'Dezembro', value: 12 },

]

export const dayOfWeekNameByDate = (dateString) => {
    return dayOfWeekName(moment.utc(dateString).day());
}

const dayOfWeekName = (number) => {
    if (number === 0) {
        return 'Domingo';
    }
    else if (number === 1) {
        return 'Segunda-Feira';
    }
    else if (number === 2) {
        return 'Terça-Feira';
    }
    else if (number === 3) {
        return 'Quarta-Feira';
    }
    else if (number === 4) {
        return 'Quinta-Feira';
    }
    else if (number === 5) {
        return 'Sexta-Feira';
    }
    else if (number === 6) {
        return 'Sábado';
    }
}


export {
    dateFormat,
    dateTimeFormat,
    dateFormatMoment,
    timeFormat,
    dateTimeTz,
    dayOfWeekName,
    dayOfWeekList
};