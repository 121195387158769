import { Grid } from '@mui/material';
import React from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import DeleteIcon from "@mui/icons-material/Delete";

const AudioRecorderContainer = ({ listAudioElement, addAudioElement, handleDelete }) => {


    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );


    return (
        <div>
            <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                downloadFileExtension="mp3"
                showVisualizer={true}
            />
            <br />
            {listAudioElement.map((coreografiaNotaAudio) => (
                <Grid container direction='row' spacing={3}>
                    <Grid item xs={3}>
                        <audio src={coreografiaNotaAudio.blobAudio} controls={true} />

                    </Grid>
                    <Grid item xs={3}>
                        <DeleteIcon
                            sx={{ mr: 5, mt: 2 }}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={() => {
                                handleDelete(coreografiaNotaAudio.codigo);
                            }}
                            color="secondary"
                        />
                    </Grid>
                </Grid>

            ))}


        </div>
    );
}

export default AudioRecorderContainer;