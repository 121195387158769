import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'coreografia-nota-audio';

export const findByCoreografiaCodigoAndCurrentUser = (codCoreografia) => {
  const url = SERVER_URL + controller + '/query?cod-coreografia=' + codCoreografia + '&current-user=true';
  return fetchGetAuth(url);
}

export const findCoreografiaNotaListAudioByCoreografiaCodigo = (codCoreografia) => {
  const url = SERVER_URL + controller + '/query-list-coreografia-nota-audio?cod-coreografia=' + codCoreografia;
  return fetchGetAuth(url);
}

export const saveCoreografiaNotaAudio = (coreografiaNotaAudio) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, coreografiaNotaAudio);
}

export const removeCoreografiaNotaAudio = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}
