import { SERVER_URL } from '../../constants';
import { fetchGetAuth, fetchPostAuth, fetchPostAuthFile, fetchRemoveAuthReturn } from '../../util/FetchUtil';

const controller = 'coreografia';

export const findByEventoDataCategoriaCodigo = (codEventoDataCategoria, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?cod-evento-data-categoria=' + codEventoDataCategoria +
    '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
};

export const findCoreografiaByEventoCodigo = (codEvento, pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?cod-evento=' + codEvento +
    '&page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
};

export const removeCoreografia = (codigo) => {
  const url = SERVER_URL + controller + "/" + codigo;
  return fetchRemoveAuthReturn(url);
}

export const saveCoreografia = (categoria) => {
  const url = SERVER_URL + controller + "/save";
  return fetchPostAuth(url, categoria);
}

export const findByInstituicaoCodigo = (pageNumber, size) => {
  const url = SERVER_URL + controller + '/query-page?page=' + pageNumber + '&size=' + size;
  return fetchGetAuth(url);
}

export const findCoreografiaByCodigo = (codigo) => {
  const url = SERVER_URL + controller + '/query-unique?codigo=' + codigo;
  return fetchGetAuth(url);
}

export const uploadMusic = (formData, codCoreografia) => {
  const url = SERVER_URL + controller + '/upload-music/' + codCoreografia;
  return fetchPostAuthFile(url, formData);
}

export const removeUploadedMusic = (codigo) => {
  const url = SERVER_URL + controller + "/remove-uploaded-music/" + codigo;
  return fetchRemoveAuthReturn(url);
}