import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { MenuRouter } from './MenuRouter';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; //
import './App.css';
import { ptBR } from "@mui/x-date-pickers/locales";

const theme = createTheme({

  palette: {
    primary: {
      main: '#91e8fc',
    },
    secondary: {
      main: '#fcb13e',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit', // removes uppercase transformation
        }
      }
    }
  }
},
  ptBR, // use 'de' locale for UI texts (start, next month, ...)
);

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none', // removes uppercase transformation
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: 12
    },
  },
  MuiSelect: {
    root: {
      fontSize: 12
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: 12
    },
  },
  MuiTableCell: {
    root: {
      fontSize: 12,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 12,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 12,
    },
  },

};


function App() {
  return (
    <div>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <MenuRouter />
      </ThemeProvider>

    </div>
  );
}

export default App;
