import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { perfilText } from "./Usuario/Perfil";
import { css } from "@emotion/react";

const WelcomeUser = () => {
  const usuario = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <Typography
        css={css({
          fontWeight: "1000",
        })}
        variant="body1"
      >
        Seja Bem Vindo
      </Typography>
      <Typography variant="body1">
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Usuário:
        </span>{" "}
        {usuario ? usuario.nome : ""}
      </Typography>
      <Typography variant='body1'>
        {usuario && usuario.instituicao &&
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Instituição:
            </span>{" "}{usuario.instituicao.descricao}
          </>}
      </Typography>
      <Typography variant="body1">
        {usuario && usuario.agenciaEvento &&
          <>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Agência Evento:
            </span>{" "}{usuario.agenciaEvento.descricao}
          </>}
      </Typography>
      <Typography variant="body1">
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Perfil:
        </span>{" "}
        {usuario ? perfilText(usuario.perfil) : ""}
      </Typography>

    </React.Fragment>
  );
};

export default WelcomeUser;
