import {
  Divider,
  Grid,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Loading from "../UI/Loading";
import Title from "../UI/Title";
import { participantePerfilEnumText } from "../ParticipantePerfil/ParticipantePerfilEnum";
import MenuIcon from '@mui/icons-material/Menu';
import { reportCertificadoParticipacao } from "./api-coreografia-participante-perfil";

const CoreografiaParticipantePerfilTableView = ({
  listCoreografiaParticipantePerfis,
  handleChangePage,
  isLoading,
  size,
  handleChangeRowsPerPage,
  coreografia
}) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCertificadoParticipacaoReport = () => {
    handleMenuClose();
    reportCertificadoParticipacao(coreografia.codigo).then((data) => {
      if (data) {
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Title>Coreografia Participante</Title>
          </Grid>
          <Grid item style={{ cursor: 'pointer' }}>
            <MenuIcon
              onClick={(event) => handleMenuClick(event)}
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleOpenCertificadoParticipacaoReport}
              >
                Certificado de Participação
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

      </Grid>
      <TablePagination
        component="div"
        count={listCoreografiaParticipantePerfis.totalElements}
        page={
          listCoreografiaParticipantePerfis.number
            ? listCoreografiaParticipantePerfis.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
      <Grid container direction='row' spacing={3}>
        {isLoading ? (
          <Loading description="Carregando participantes..." />
        ) : (
          <>
            {listCoreografiaParticipantePerfis.content.length === 0 ? (
              <Grid item>
                <Typography sx={{ py: 3 }}>
                  Nenhum participante cadastrado
                </Typography>
              </Grid>
            ) : (
              <>
                {listCoreografiaParticipantePerfis.content.map((cpp) => (
                  <>
                    <Grid item xs={3}>
                      <p><span style={{ fontWeight: 'bold' }}>Nome: </span>{cpp.participantePerfil
                        ? cpp.participantePerfil.participante.nome
                        : ""}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p><span style={{ fontWeight: 'bold' }}>Perfil: </span>{cpp.participantePerfil
                        ? participantePerfilEnumText(cpp.participantePerfil.participantePerfilEnum)
                        : ""}</p>
                    </Grid>
                    <Grid item xs={12}><Divider /></Grid>
                  </>
                ))}
              </>
            )}
          </>
        )}

      </Grid>
      <TablePagination
        component="div"
        count={
          listCoreografiaParticipantePerfis.totalElements
            ? listCoreografiaParticipantePerfis.totalElements
            : 0
        }
        page={
          listCoreografiaParticipantePerfis.number
            ? listCoreografiaParticipantePerfis.number
            : 0
        }
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </React.Fragment>
  );
};

export default CoreografiaParticipantePerfilTableView;
