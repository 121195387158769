import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { DIRETOR_EVENTO, DIRETOR_INSTITUICAO } from "./Usuario/Perfil";
import Title from "./UI/Title";

const GuideFirstSteps = () => {
  const usuario = useSelector((state) => state.user);

  return (
    <React.Fragment>
      <Title>Primeiros Passos</Title>
      <Typography variant='body1'>Faça o cadastro na seguinte ordem:</Typography>
      {usuario.perfil === DIRETOR_EVENTO &&
        <ol>
          <li>Evento</li>
          <li>Evento Data</li>
          <li>Categoria</li>
          <li>Indicação</li>
          <li>Evento Data Categoria</li>
          <li>Usuário Perfil (Criação do Jurado)</li>
          <li>Jurado (Vinculação Jurado com Evento Data)</li>
        </ol>
      }
      {usuario.perfil === DIRETOR_INSTITUICAO &&
        <ol>
          <li>Participante</li>
          <li>Coreografia</li>
          <li>Coreografia Participante</li>
        </ol>
      }


    </React.Fragment>
  );
};

export default GuideFirstSteps;
